import React, { useState } from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./App.css"
// import StatusComponent from "./StatusComponent"
// import StatusComponentMobile from "./StatusComponentMobile"

import { ethers } from "ethers"
import injectedModule from "@web3-onboard/injected-wallets"
import ledgerModule from "@web3-onboard/ledger"
import walletConnectModule from "@web3-onboard/walletconnect"
import trezorModule from "@web3-onboard/trezor"
import gnosisModule from "@web3-onboard/gnosis"
import coinbaseWalletModule from "@web3-onboard/coinbase"
import frameModule from "@web3-onboard/frame"
import { init, useConnectWallet } from "@web3-onboard/react"
import {
  controllerAddress,
  controllerAddressBlast,
  controllerABI,
  resultsTopic,
} from "./controller"
import ReactModal from "react-modal"
// Other icons:
import githubIcon from "./images/github.svg"
import etherscanIcon from "./images/etherscan.svg"
// import twitterIcon from "./images/twitter.png"
import npmIcon from "./images/npm.png"
import gitbooksIcon from "./images/gitbooks.svg"
// import discordIcon from "./images/discord.png"
// Carousel images:
// Mainnets:
import mainnetIcon from "./images/ethmainnet.png"
import arbOneIcon from "./images/arbone.png"
import optimismIcon from "./images/optimism.png"
import polygonIcon from "./images/polygon.png"
import blastIcon from "./images/blast.png"
// import mainnetIconWhite from "./images/ethmainnetwhite.png"
// import arbOneIconWhite from "./images/arbonewhite.png"
// import optimismIconWhite from "./images/optimism.png"
// import polygonIconWhite from "./images/polygonwhite.png"
// Testnets:
import goerliIcon from "./images/ethgoerli.png"
import sepoliaIcon from "./images/ethsepolia.png"
import arbGoerliIcon from "./images/arbgoerli.png"
import optimismGoerliIcon from "./images/optimismgoerli.png"
import polygonMumbaiIcon from "./images/polygonmumbai.png"
import blastSepoliaIcon from "./images/blastSepolia.png"
// import goerliIconWhite from "./images/ethgoerliwhite.png"
// import sepoliaIconWhite from "./images/ethsepoliawhite.png"
// import arbGoerliIconWhite from "./images/arbgoerliwhite.png"
// import optimismGoerliIconWhite from "./images/optimismgoerliwhite.png"
// import polygonMumbaiIconWhite from "./images/polygonmumbaiwhite.png"
// gifs
// import dieForward from "./images/dieForward.gif"
// import dieBackward from "./images/dieBackward.gif"
import icosaspin from "./images/icosaspinfast.gif"
import { Buffer } from "buffer"

import { atomOneDark, CodeBlock, a11yDark } from "react-code-blocks"
import { CopyToClipboard } from "react-copy-to-clipboard"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faCopy } from "@fortawesome/free-regular-svg-icons"

/* global BigInt */
window.Buffer = window.Buffer || Buffer
ReactModal.setAppElement("#root")

// =====================================================
// Setup Web3-onboard
// vvvvvvvvvvvvvvvvvv
// =====================================================
const injected = injectedModule()
const walletConnect = walletConnectModule()
const ledger = ledgerModule()
const trezor = trezorModule({
  email: 'arr@arrng.io"',
  appUrl: "https://www.arrng.io/",
})
const gnosis = gnosisModule()
const coinbaseWalletSdk = coinbaseWalletModule({ darkMode: true })
const frame = frameModule()
const appMetadata = {
  name: "connected to arrng",
  icon: "/icoMain40.svg",

  description: "easy on-chain randonmess",
}
const theme = {
  "--w3o-background-color": "#3d3d3d",
  "--w3o-foreground-color": "black",
  "--w3o-text-color": "#fff",
  "--w3o-border-color": "#e7d6bd",
  "--w3o-action-color": "#e7d6bd",
  "--w3o-border-radius": "10px",
}
const rpc = {
  rpc_1: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
  rpc_42161: "https://arb1.arbitrum.io/rpc",
  rpc_10: "https://optimism.publicnode.com",
  rpc_137: "https://endpoints.omniatech.io/v1/matic/mainnet/public",
  rpc_5: "https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
  rpc_11155111: "https://ethereum-sepolia.blockpi.network/v1/rpc/public",
  rpc_421613: "https://goerli-rollup.arbitrum.io/rpc",
  rpc_420: "https://endpoints.omniatech.io/v1/op/goerli/public",
  rpc_80001: "https://endpoints.omniatech.io/v1/matic/mumbai/public",
  rpc_168587773: "https://sepolia.blast.io",
}

init({
  theme,
  wallets: [
    injected,
    ledger,
    walletConnect,
    trezor,
    gnosis,
    coinbaseWalletSdk,
    frame,
  ],
  chains: [
    {
      id: 1,
      token: "ETH",
      label: "Eth Mainnet",
      rpcUrl: rpc["rpc_1"],
    },
    {
      id: 42161,
      token: "ETH",
      label: "Arbitrum One",
      rpcUrl: rpc["rpc_42161"],
    },
    {
      id: 10,
      token: "ETH",
      label: "Optimism",
      rpcUrl: rpc["rpc_10"],
    },
    {
      id: 137,
      token: "MATIC",
      label: "Polygon",
      rpcUrl: rpc["rpc_137"],
    },
    // {
    //   id: 5,
    //   token: "ETH",
    //   label: "Eth Goerli",
    //   rpcUrl: rpc["rpc_5"],
    // },
    {
      id: 11155111,
      token: "ETH",
      label: "Eth Sepolia",
      rpcUrl: rpc["rpc_11155111"],
    },
    // {
    //   id: 421613,
    //   token: "ETH",
    //   label: "Arbitrum Goerli",
    //   rpcUrl: rpc["rpc_421613"],
    // },
    // {
    //   id: 420,
    //   token: "ETH",
    //   label: "Optimism Goerli",
    //   rpcUrl: rpc["rpc_420"],
    // },
    // {
    //   id: 80001,
    //   token: "MATIC",
    //   label: "Polygon Mumbai",
    //   rpcUrl: rpc["rpc_80001"],
    // },
    // {
    //   id: 168587773,
    //   token: "ETH",
    //   label: "Blast Sepolia",
    //   rpcUrl: rpc["rpc_168587773"],
    // },
  ],
  appMetadata,
})
// =====================================================
// ^^^^^^^^^^^^^^^^^^
// Setup Web3-onboard
// =====================================================

function App() {
  // =====================================================
  // State variables
  // vvvvvvvvvvvvvvvvvv
  // =====================================================
  const [selectedChainForGasCalc, setSelectedChainForGasCalc] = useState("1")
  const [selectedChainForResponse, setSelectedChainForResponse] = useState("1")
  const [selectedComplexityForGasCalc, setSelectedComplexityForGasCalc] =
    useState("1")
  const [selectedQuantityForGasCalc, setSelectedQuantityForGasCalc] =
    useState(1)
  const [calculationResult, setCalculationResult] = useState(null)
  const [selectedQuantityForExecution, setSelectedQuantityForExecution] =
    useState(1)
  const [selectedNumberType, setSelectedNumberType] = useState('maxUint');
  const [minNumber, setMinNumber] = useState(0);
  const [maxNumber, setMaxNumber] = useState(0);
  const [requestResponse, setRequestResponse] = useState(null)
  const [requestID, setRequestId] = useState(null)
  const [requestIDForCheck, setRequestIDForCheck] = useState("")
  const [requestIsProcessing, setRequestIsProcessing] = useState(false)
  const [calcIsProcessing, setCalcIsProcessing] = useState(false)
  const [checkIsProcessing, setCheckIsProcessing] = useState(false)
  const [noResponseYet, setNoResponseYet] = useState(false)
  const [errorModalOpen, setErrorModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  // State change handlers:
  const handleChangeOfChainForGasCalc = (event) => {
    setSelectedChainForGasCalc(event.target.value)
  }
  const handleChangeOfChainForResponse = (event) => {
    setSelectedChainForResponse(event.target.value)
  }
  const handleChangeOfComplexityForGasCalc = (event) => {
    setSelectedComplexityForGasCalc(event.target.value)
  }
  const handleChangeOfQuantityForGasCalc = (event) => {
    setSelectedQuantityForGasCalc(event.target.value)
  }
  const handleChangeOfQuantityForExecution = (event) => {
    setSelectedQuantityForExecution(event.target.value)
  }
  const handleChangeOfNumberType = (event) => {
    setSelectedNumberType(event.target.value);
    // Automatically reset min and max numbers when changing type
    if (event.target.value === 'maxUint') {
      setMinNumber(0);
      setMaxNumber(0);
    }
  };

  const handleMinNumberChange = (event) => {
    if (selectedNumberType !== 'maxUint') {
      setMinNumber(event.target.value);
    }
  };

  const handleMaxNumberChange = (event) => {
    if (selectedNumberType !== 'maxUint') {
      setMaxNumber(event.target.value);
    }
  };

  const handleChangeOfrequestIDForCheck = (event) => {
    setRequestIDForCheck(event.target.value)
  }

  // Error modal handlers:
  const openErrorModal = (message) => {
    setErrorModalOpen(true)
    setErrorMessage(message)
  }

  const closeErrorModal = () => {
    setErrorModalOpen(false)
  }
  // =====================================================
  // ^^^^^^^^^^^^^^^^^^
  // State variables
  // =====================================================

  // =====================================================
  // Core configuration
  // vvvvvvvvvvvvvvvvvv
  // =====================================================
  // Logging?
  const logging = false

  // Params for estimates:
  const arrngOracle = "0x24a088AC51871780fCc4c3b02FAfB0b4e0C98B4e"
  const sampleBigNumber =
    "0x2f4e34c2e65889cb3bd8836a6aa41071c3b123bc7277a740070decb05c144f00"
  const sampleReturnString = `{"method":"generateSignedBlobs","hashedApiKey":"H7BMHKGIjChN9q3wgxWk3GwrHna/XeAK8xbIzMp3DwBgHH6KwNE1uj3CfO+76W/a7eKqfEHi6m8DU8L+PTNLVA==","n":1,"size":256,"format":"hex","pregeneratedRandomization":null,"data":["3d0dd7e4845274511428f89ac4f90a952a351720e4a20d25463e1ae118af9885"],"license":{"type":"gambling-virtual","text":"Random values licensed for virtual item gambling only","infoUrl":null},"licenseData":null,"userData":"request: 2, txn: 0x80195d7b02f1116e3aa3ce73ff0ba99b7089783e88b92159a58201025e0718a6","ticketData":null,"completionTime":"2023-05-27 09:42:43Z","serialNumber":3}`
  const sampleReturnSignature =
    "VJFCib0KCfM4MC6pwEGgHLGOyXEZv00PozgXMbdeN41tApnnQ8UoJOolaRkbCYQXe8TmbbOk4ZmDU+sbWx457+141hXyvXKJhw9PnSf1OhIGkssL+QAcfUqgLpEK3OjEfL0hVzKKPbJLwdKiiz1qEZU4oKpMDmBSN+OROsepOJsj4VwqE438llUfgGPl99GBcatRg8B4JjybF4JHHqYsluQYCYmISwoUji+TnCsqyj40OJjPbRBxqyhPxLDh6hRrEhcEX6Xa/11tzufV2GipbJn9QnEFTkGb8Hp7J9w8IO1s/DbfoXmG3+Nv0SS76taQ+3AmMpjKs02SEKwH/La0dTUk0CTN3eJj61AMwzCdH9RXE9NBHUWqap7Gu4oM3gLcRma7WUn+kkEAeSk1ayMuB4/Fl+sRJ+/myrwQi14O3O/thQr7QGHkoi81+sNQZEYA7v3RW/WfP0AxzLrUYJgp79uR3rAgSD8HtDDB18qt+rn584zCzwYIg9lGp2apO5E7wDBdnV+Tcf4654jwVtjYVhd6gx9mQHCfm9sRCeNnWS5UK+xaGkBfSW/kpQCv+tBIy/FIiRwPXdEDbaqO/8gU06KLyJ+PafK5vGjw+yruTFi8DJneZVMZHmAw9XoX7hrNlJ+qRB39psuCJVq52YrRgPnh6qH7MWShSQRYOfgm4f0="
  const gasCalcSafetyMargin = 133
  const gasPriceSafetyMargin = 150
  const ethFee = "1000000000000000"
  const maticTestnetFee = "2000000000000000"
  const maticMainnetFee = "2000000000000000000"

  // Deterime priority fee:
  const priorityFeeState = {
    1: 1000000000, // mainnet 1 gwei
    42161: 100000000, // arbitrum one 0.1 gwei
    10: 100000000, // optimism 0.1 gwei
    137: 30000000000, // polygon 30 gwei
    56: 0, // BSC
    // Testnet APIs
    5: 1000000000, // goerli 1 gwei
    11155111: 1000000000, // sepolia 1 gwei
    421613: 100000000, // arbitrum goerli 0.1 gwei
    420: 100000000, // optimism goerli 0.1 gwei
    80001: 2000000000, // polygon mumbai 2 gwei
    97: 0, // BSC Testnet
    168587773: 25000000000, // blast sepolia 25 gwei
  }

  // Determine arrng fee:
  const feeForChainState = {
    1: ethFee, // mainnet 1 gwei
    42161: ethFee, // arbitrum one 0.1 gwei
    10: ethFee, // optimism 0.1 gwei
    137: maticMainnetFee, // polygon 30 gwei
    56: ethFee, // BSC
    // Testnet APIs
    5: ethFee, // goerli 1 gwei
    11155111: ethFee, // sepolia 1 gwei
    421613: ethFee, // arbitrum goerli 0.1 gwei
    420: ethFee, // optimism goerli 0.1 gwei
    80001: maticTestnetFee, // polygon mumbai 0.1 gwei
    97: ethFee, // BSC Testnet
    168587773: ethFee, // blast sepolia 0.1 gwei
  }

  // Deterime gas multiplier:
  const gasMultiplierState = {
    mulGasComplexity0: 100,
    mulGasComplexity1: 150,
    mulGasComplexity2: 200,
    mulGasComplexity3: 250,
  }
  // =====================================================
  // ^^^^^^^^^^^^^^^^^^
  // Core configuration
  // =====================================================

  // =====================================================
  // Carousel configuration
  // vvvvvvvvvvvvvvvvvv
  // =====================================================
  const images = [
    mainnetIcon,
    arbOneIcon,
    optimismIcon,
    polygonIcon,
    sepoliaIcon,
  ]

  // const imagesWhite = [
  //   mainnetIconWhite,
  //   arbOneIconWhite,
  //   optimismIconWhite,
  //   polygonIconWhite,
  //   goerliIconWhite,
  //   arbGoerliIconWhite,
  //   optimismGoerliIconWhite,
  //   polygonMumbaiIconWhite,
  //   sepoliaIconWhite,
  // ]

  const settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    variableWidth: true,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: true,
    cssEase: "linear",
    initialSlide: images.length,
    rtl: false,
    beforeChange: (current, next) => {
      if (next === images.length) {
        setTimeout(() => {
          sliderRef.current.slickGoTo(0, false)
        }, 0)
      }
    },
  }

  const sliderRef = React.useRef(null)
  // =====================================================
  // ^^^^^^^^^^^^^^^^^^
  // Carousel configuration
  // =====================================================

  // Wallets and providers:
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet()
  let ethersProvider

  // Contract parameters:
  const iface = new ethers.Interface(controllerABI)
  const aarngResponseFilter = {
    address: controllerAddress,
    topics: resultsTopic,
  }

  const aarngResponseFilterBlast = {
    address: controllerAddressBlast,
    topics: resultsTopic,
  }

  const codeSnippet1 = `import {ArrngConsumer} from @arrng/contracts/ArrngConsumer.sol;
contract YourContract isArrngConsumer {
  constructor(address controller) ArrngConsumer(controller) {}
   << your code >>
}`
  const codeSnippet2 = `arrngController.requestRandomWords {value: msg.value} (1);`
  const codeSnippet3 = `function fulfillRandomWords(
  uint256 id, uint256[] memory numbers
  ) internal override { << do something with numbers[0] >> }`
  const codeSnippet4 = `// Pass native token (e.g. ETH) for return gas. Excess is refunded.
https://api.arrng.io/getCost?
  chain=<chain>&quantity=<quantity>&complexity=<complexity>
// chain Id e.g. 1 for ETH Mainnet
// quantity: number of numbers e.g. 1
// complexity: complexity of return function
//   1 = Simple, e.g. store a single var
//   2 = Medium, e.g. 2 to 3 writes>
//   3 = Complex. Lots of processing`
  const codeSnippet5 = `// Pass required native token on call, e.g.:
await contractWithSigner.connect(signer)
  ["requestRandomWords(uint256)"](1, {value: <costFromAPI>})`

  function currentControllerContract(chainId) {
    if (chainId === 168587773) {
      return controllerAddressBlast
    } else {
      return controllerAddress
    }
  }

  function currentResponseFilter(chainId) {
    if (chainId === 168587773) {
      return aarngResponseFilterBlast
    } else {
      return aarngResponseFilter
    }
  }

  // Get the cost of the call in native token:
  async function getCost(provider, quantity, complexity) {
    // Get the chain:
    const chainId = (await provider.getNetwork()).chainId

    // Get the gas units for the response to this RNG request:
    const contract = new ethers.Contract(
      currentControllerContract(chainId),
      controllerABI,
      provider,
    )

    // Construct the sample numbers array to the correct number of numbers:
    const sampleBigNumbers = new Array(Number(quantity)).fill(sampleBigNumber)

    if (logging) console.log(sampleBigNumbers)

    // Parse it as JSON
    let obj = JSON.parse(sampleReturnString)

    // Modify the 'data' property
    obj.data = sampleBigNumbers

    // Convert it back to a string
    let stringWithCorrectArray = JSON.stringify(obj)

    if (logging) console.log(stringWithCorrectArray)

    // Get the gas limit:
    const gasLimit = BigInt(
      await contract.serveRandomness.estimateGas(
        1,
        arrngOracle,
        sampleBigNumber,
        0,
        sampleBigNumbers,
        arrngOracle,
        stringWithCorrectArray,
        sampleReturnSignature,
        1,
        { from: arrngOracle, value: 1 },
      ),
    )

    if (logging) console.log("gasLimit", gasLimit)

    // Determine complexity multiplier:
    const mulGasComplexity = BigInt(
      gasMultiplierState["mulGasComplexity" + complexity],
    )

    // Determine the fee:
    const feeForChain = BigInt(feeForChainState[chainId])

    if (logging) console.log("feeForChain", feeForChain)

    // Get the current gas price from ethers:
    const gasPriceWei = BigInt((await provider.getFeeData()).gasPrice)

    if (logging) console.log("gasPriceWei", gasPriceWei)

    // Get the priority fee for this chain:
    const priorityFee = BigInt(priorityFeeState[chainId])

    if (logging) console.log("priorityFee", priorityFee)

    // Add the priority fee to the returned gas price:
    const gasPriceWithPriority = gasPriceWei + priorityFee

    if (logging) console.log("gasPriceWithPriority", gasPriceWithPriority)

    // Apply compexity modifier:
    const gasLimitWithComplexityModifier =
      (gasLimit * mulGasComplexity) / BigInt("100")

    // Apply safety margin to both gas unit calc and price:
    const safetyGasLimit =
      (gasLimitWithComplexityModifier * BigInt(gasCalcSafetyMargin)) /
      BigInt("100")
    const safetyGasPrice =
      (gasPriceWithPriority * BigInt(gasPriceSafetyMargin)) / BigInt("100")

    if (logging) console.log("safetyGasLimit", safetyGasLimit)
    if (logging) console.log("safetyGasPrice", safetyGasPrice)

    // Total cost is (gas units * gas limit) + fee:
    let calculatedCost = safetyGasPrice * safetyGasLimit + feeForChain

    if (logging) console.log("calculatedCost", calculatedCost)

    return calculatedCost
  }

  function roundUpToDecimal(number, decimalPlaces) {
    const factor = 10 ** decimalPlaces
    return Math.ceil(number * factor) / factor
  }

  // Handle a call to getCost:
  const handleGetCost = async (event) => {
    event.preventDefault()
    setCalculationResult(null)

    if (selectedQuantityForGasCalc <= 0) {
      openErrorModal("You must request at least 1 number")
      return
    }

    if (selectedChainForGasCalc === "0") {
      openErrorModal(
        "BLAST mainnet isn't live yet. We will be ready the second it is! You can test your dapps on BLAST Sepolia.",
      )
      return
    }

    setCalcIsProcessing(true)

    try {
      const calculatedCost = await getCost(
        new ethers.JsonRpcProvider(rpc["rpc_" + selectedChainForGasCalc]),
        selectedQuantityForGasCalc,
        selectedComplexityForGasCalc,
      )
      const unroundedInEth = ethers.formatEther(calculatedCost)
      const roundedCost = roundUpToDecimal(Number(unroundedInEth), 4)
      //const roundedCost = Number(unroundedInEth).toFixed(8)

      // Perform any other actions with the gas price
      setCalculationResult(roundedCost)
    } catch (error) {
      console.error("Error retrieving gas price:", error)
      // Handle the error appropriately
    }
    setCalcIsProcessing(false)
  }

  // Handle a call to getRandom
  const handleGetRandom = async (event) => {
    event.preventDefault()

    if (wallet) {
      ethersProvider = new ethers.BrowserProvider(wallet.provider, "any")
    } else {
      openErrorModal("Please connect your wallet first")
      return
    }

    const chainId = Number((await ethersProvider.getNetwork()).chainId)

    setRequestId(null)

    let calculatedCost

    if (selectedQuantityForExecution <= 0) {
      openErrorModal("You must request at least 1 number")
      return
    }

    try {
      calculatedCost = await getCost(
        ethersProvider,
        selectedQuantityForExecution,
        2,
      )
    } catch (error) {
      console.error("Error retrieving gas price:", error)
    }

    const unroundedInEth = ethers.formatEther(calculatedCost.toString())
    const roundedCost = roundUpToDecimal(Number(unroundedInEth), 4)
    // And put back in wei
    calculatedCost = ethers.parseEther(roundedCost.toString())

    const signer = await ethersProvider.getSigner()

    const contractWithSigner = new ethers.Contract(
      currentControllerContract(chainId),
      controllerABI,
      signer,
    )

    const contractWithProvider = new ethers.Contract(
      currentControllerContract(chainId),
      controllerABI,
      ethersProvider,
    )

    try {
      // Check the holders balance is sufficient. To do this we need to call estimateGas
      // from an address we know will pass:

      // Get gas estimate for call:
      const gasLimit = BigInt(
        await contractWithProvider["requestRandomWords(uint256)"].estimateGas(
          selectedQuantityForExecution,
          {
            value: calculatedCost,
          },
        ),
      )

      const gasPriceWei = BigInt((await ethersProvider.getFeeData()).gasPrice)

      const callGasCost = gasLimit * gasPriceWei

      const totalCost = callGasCost + calculatedCost

      const callerAddress = await signer.getAddress()

      const callerBalance = await ethersProvider.getBalance(callerAddress)

      if (logging) console.log("callerBalance", callerBalance)
      if (logging) console.log("totalCost", totalCost)

      if (callerBalance < totalCost) {
        let balanceEth = ethers.formatEther(callerBalance)
        let totalCostEth = ethers.formatEther(totalCost)
        openErrorModal(
          "Insufficient funds. You have " +
          parseFloat(Number(balanceEth).toFixed(8)) +
          " and need " +
          parseFloat(Number(totalCostEth).toFixed(8)),
        )
        return
      }

      // Make the contract call
      let tx
      // Different request if we are getting a number in range:
      if (selectedNumberType !== "maxUint") {
        if (minNumber === maxNumber) {
          openErrorModal("Max must be greater than min")
          return
        }

        tx = await contractWithSigner
          .connect(signer)
        ["requestRandomNumbersInRange(uint256,uint256,uint256)"](selectedQuantityForExecution, minNumber, maxNumber, {
          value: calculatedCost,
        })
      } else {
        tx = await contractWithSigner
          .connect(signer)
        ["requestRandomWords(uint256)"](selectedQuantityForExecution, {
          value: calculatedCost,
        })
      }

      setRequestIsProcessing(true)

      const receipt = await tx.wait()

      if (receipt.status !== 1) {
        console.error("Failed to call contract:")
      }

      let requestID

      if (chainId === 137 || chainId === 80001) {
        requestID = receipt.logs[2].topics[2]
      } else {
        requestID = receipt.logs[0].topics[2]
      }

      setRequestIsProcessing(false)
      setRequestId(requestID)
    } catch (error) {
      console.error("Failed to call contract:", error)
    }
  }

  // Handle a call to getResponse:
  const handleGetResponse = async (event) => {
    event.preventDefault()
    setRequestResponse(null)
    setCheckIsProcessing(true)
    setNoResponseYet(false)

    let found = false
    try {
      const provider = new ethers.JsonRpcProvider(
        rpc["rpc_" + selectedChainForResponse],
      )

      const logs = await provider.getLogs({
        address: currentResponseFilter(selectedChainForResponse).address,
        topics: aarngResponseFilter.topics,
        fromBlock: 0,
        toBlock: "latest",
      })

      for (let i = 0; i < logs.length; i++) {
        const log = logs[i]
        const parsedLog = iface.parseLog(log)
        if (Number(parsedLog.args.requestId) === Number(requestIDForCheck)) {
          setRequestResponse(parsedLog)
          found = true
          break // Exit the function after setting the response
        }
      }
    } catch (error) {
      console.error("Error retrieving response:", error)
    }

    setCheckIsProcessing(false)

    if (!found) {
      if (selectedChainForResponse === "168587773") {
        openErrorModal(
          "RPCs for BLAST Sepolia currently have a limited search range. Please use arrscan for up to date information.",
        )
      } else {
        setNoResponseYet(true)
      }
    }
  }

  const handleOpenArrscan = () => {
    window.open("https://arrscan.io", "_blank")
  }

  const handleOpenRandomOrg = () => {
    window.open("https://api.random.org/signatures/form", "_blank")
  }

  // const CopyToClipboardFromAbbreviated = ({ fullText, abbreviatedText }) => {
  //   const copyToClipboard = () => {
  //     const textField = document.createElement("textarea")
  //     textField.value = fullText
  //     document.body.appendChild(textField)
  //     textField.select()
  //     document.execCommand("copy")
  //     textField.remove()
  //   }

  //   return (
  //     <div className="copy-to-clipboard">
  //       <span>{abbreviatedText}</span>
  //       <button onClick={copyToClipboard}>
  //         <FontAwesomeIcon icon={faCopy} />
  //       </button>
  //     </div>
  //   )
  // }

  return (
    <div className="App">
      {/* <img
        src="/ico0.svg"
        alt="arrng"
        style={{ height: "30px", float: "left" }}
      /> */}
      {/* <div className="Announcement">
        <img src="./blastwordmark.png" alt="BLAST" height="50px" />
        sepolia support is LIVE!
      </div>
      <div className="AnnouncementMobile">
        <img src="./blastwordmark.png" alt="BLAST" height="30px" />
        <span style={{ marginLeft: "20px", marginRight: "20px" }}>
          sepolia support is LIVE!
        </span>
      </div> */}
      <div className="HeroBanner">arrng.io</div>
      <h2 className="Heading">supported chains</h2> {/* Add the heading */}
      <div className="Carousel">
        <Slider ref={sliderRef} {...settings}>
          {images.map((image, index) => (
            <div key={index} className="CarouselItem">
              <img src={image} alt={`supported chain ${index + 1}`} />
            </div>
          ))}
        </Slider>
      </div>
      <div className="MobileSupportedChains">
        <Tabs>
          <TabList className="tab-list">
            <Tab className="custom-tab">SUPPORTED CHAINS</Tab>
          </TabList>

          <TabPanel className="TabPanelForSupportedChains">
            {images.map((image, index) => (
              <div key={index} className="SupportedChainItem">
                <img src={image} alt={`Supported chain ${index + 1}`} />
              </div>
            ))}
          </TabPanel>
        </Tabs>
      </div>
      <div className="Content">
        <div className="LeftSide">
          <Tabs>
            <TabList className="tab-list">
              <Tab className="custom-tab">IMPLEMENT: sol</Tab>
              <Tab className="custom-tab">IMPLEMENT: app</Tab>
            </TabList>

            <TabPanel className="TabPanel">
              <div className="Step">IMPORT</div>
              <br></br>
              <div className="content-wrapper">
                <div>
                  Get the controller address for your chain:&nbsp;
                  <a
                    href="https://docs.arrng.io/controller-contract-addresses"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "white" }}
                  >
                    docs.arrng.io
                  </a>
                </div>
              </div>
              <div style={{ position: "relative", width: "100%" }}>
                <CodeBlock
                  text={codeSnippet1}
                  language="solidity"
                  showLineNumbers={false}
                  wrapLongLines={true}
                  theme={atomOneDark} // Use the "atomOneDark" theme
                />
                <CopyToClipboard text={codeSnippet1}>
                  <button
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      padding: "4px 8px",
                      background: "rgba(0, 0, 0, 0.2)",
                      color: "white", // White text color
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    Copy Code
                  </button>
                </CopyToClipboard>
              </div>
              <br></br>
              <div className="Step">REQUEST</div>
              <br></br>
              <div style={{ position: "relative", width: "100%" }}>
                <CodeBlock
                  text={codeSnippet2}
                  language="solidity"
                  showLineNumbers={false}
                  wrapLongLines={true}
                  theme={atomOneDark} // Use the "atomOneDark" theme
                />
                <CopyToClipboard text={codeSnippet2}>
                  <button
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      padding: "4px 8px",
                      background: "rgba(0, 0, 0, 0.2)",
                      color: "white", // White text color
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    Copy Code
                  </button>
                </CopyToClipboard>
              </div>
              <div style={{ fontSize: "14px" }}>
                (you must pass native token for gas)
              </div>
              <br></br>
              <div className="Step">USE</div>
              <br></br>
              <div style={{ position: "relative", width: "100%" }}>
                <CodeBlock
                  text={codeSnippet3}
                  language="solidity"
                  showLineNumbers={false}
                  wrapLongLines={true}
                  theme={atomOneDark} // Use the "atomOneDark" theme
                />
                <CopyToClipboard text={codeSnippet3}>
                  <button
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      padding: "4px 8px",
                      background: "rgba(0, 0, 0, 0.2)",
                      color: "white", // White text color
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    Copy Code
                  </button>
                </CopyToClipboard>
              </div>
              <br></br>
              <div style={{ textAlign: "center", marginTop: "10px" }}>
                <a
                  href="https://docs.arrng.io/implementing-in-your-contract"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "white" }}
                >
                  more instructions
                </a>
                &nbsp;
                <a
                  href="https://github.com/arrng/arrng-contracts/blob/main/contracts/mock/MockConsumer.sol"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "white" }}
                >
                  example contract
                </a>
              </div>
            </TabPanel>

            <TabPanel className="TabPanel">
              <div className="Step">GET COST</div>
              <br></br>
              <div style={{ position: "relative", width: "100%" }}>
                <CodeBlock
                  text={codeSnippet4}
                  language="solidity"
                  showLineNumbers={false}
                  theme={a11yDark} // Use the "atomOneDark" theme
                  wrapLongLines={true}
                />
                <CopyToClipboard text={codeSnippet4}>
                  <button
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      padding: "4px 8px",
                      background: "rgba(0, 0, 0, 0.2)",
                      color: "white", // White text color
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    Copy Code
                  </button>
                </CopyToClipboard>
              </div>
              <br></br>
              <div className="Step">CONTRACT CALL</div>
              <br></br>
              <div style={{ position: "relative", width: "100%" }}>
                <CodeBlock
                  text={codeSnippet5}
                  language="solidity"
                  showLineNumbers={false}
                  wrapLongLines={true}
                  theme={a11yDark} // Use the "atomOneDark" theme
                  customStyle={{
                    marginBottom: "1rem",
                  }}
                />
                <CopyToClipboard text={codeSnippet5}>
                  <button
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      padding: "4px 8px",
                      background: "rgba(0, 0, 0, 0.2)",
                      color: "white", // White text color
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    Copy Code
                  </button>
                </CopyToClipboard>
              </div>
              <div style={{ textAlign: "center", marginTop: "10px" }}>
                <a
                  href="https://docs.arrng.io/getting-a-cost-estimate"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "white" }}
                >
                  more instructions
                </a>
              </div>
            </TabPanel>
          </Tabs>
        </div>
        <div className="RightSide">
          <Tabs>
            <TabList className="tab-list">
              <Tab className="custom-tab">ABOUT</Tab>
              <Tab className="custom-tab">COST</Tab>
              <Tab className="custom-tab">GET</Tab>
              <Tab className="custom-tab">CHECK</Tab>
              <Tab className="custom-tab">VERIFY</Tab>
            </TabList>
            <TabPanel className="TabPanel">
              <h2 className="Title">VERIFIABLE RANDOMNESS</h2>
              <div className="centered-container">
                <ul
                  className="bulleted-text"
                  style={{
                    marginTop: "1rem",
                  }}
                >
                  <li>Signed and verifiable off-chain rng</li>
                  <li>Uses class-leading random.org</li>
                  <li>Response and signature on-chain</li>
                  <li>Multi-chain</li>
                  <li>Easy to use</li>
                  <li>No subsription contract</li>
                  <li>No tokens to buy</li>
                  <li>No complicated setup</li>
                  <li>Quick and efficient</li>
                </ul>
              </div>
              <div className="centered-icon-container">
                {/* <a
                  href="https://discord.gg/EUv6BHECuR"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={discordIcon}
                    alt="@arrng"
                    className="link-icon"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      width: "auto",
                      height: "auto",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  />
                </a> */}
                {/* <a
                  href="https://twitter.com/arrngcaptain"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={twitterIcon} // Replace with the path to your GitHub icon image
                    alt="@arrng"
                    className="link-icon"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      width: "auto",
                      height: "auto",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  />
                </a> */}
                <a
                  href="https://docs.arrng.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={gitbooksIcon} // Replace with the path to your GitHub icon image
                    alt="Gitbook"
                    className="link-icon"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      width: "auto",
                      height: "auto",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  />
                </a>
                <a
                  href="https://www.github.com/arrng"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={githubIcon} // Replace with the path to your GitHub icon image
                    alt="GitHub"
                    className="link-icon"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      width: "auto",
                      height: "auto",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  />
                </a>
                <a
                  href="https://www.npmjs.com/package/@arrng/contracts"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={npmIcon} // Replace with the path to your GitHub icon image
                    alt="npm"
                    className="link-icon"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      width: "auto",
                      height: "auto",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  />
                </a>
                <a
                  href="https://blockscan.com/address/0x000000000000f968845afB0B8Cf134Ec196D38D4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={etherscanIcon} // Replace with the path to your GitHub icon image
                    alt="etherscan"
                    className="link-icon"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      width: "auto",
                      height: "auto",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  />
                </a>
                <div>
                  <button
                    className="arrscan-button"
                    onClick={handleOpenArrscan}
                    disabled={requestIsProcessing}
                    onMouseEnter={(e) =>
                      (e.target.style.backgroundColor = "#ccc")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.backgroundColor = "#f5f5f5")
                    }
                  >
                    ARRSCAN
                  </button>
                </div>
              </div>
            </TabPanel>
            <TabPanel className="TabPanel">
              <h2 className="Title">NATIVE TOKEN FOR REQUEST</h2>
              <br></br>
              <form className="Form">
                <div className="form-row">
                  <label htmlFor="chainSelection">Chain:</label>
                  <select
                    id="chainSelection"
                    value={selectedChainForGasCalc}
                    onChange={handleChangeOfChainForGasCalc}
                    style={{
                      fontFamily: "courier, cursive",
                      fontSize: "12pt",
                    }}
                  >
                    <option value="1">eth mainnet</option>
                    {/* <option value="0">BLAST mainnet SOON</option> */}
                    <option value="42161">arbitrum one</option>
                    <option value="10">optimism</option>
                    <option value="137">polygon</option>
                    {/* <option value="168587773">BLAST sepolia</option> */}
                    {/* <option value="5">eth goerli</option> */}
                    <option value="11155111">eth sepolia</option>
                    {/* <option value="421613">arbitrum goerli</option>
                    <option value="420">optimism goerli</option>
                    <option value="80001">polygon mumbai</option> */}
                  </select>
                </div>
                <div className="form-row">
                  <label htmlFor="quantitySelection">Numbers requested:</label>
                  <input
                    id="quantityInput"
                    type="number"
                    value={selectedQuantityForGasCalc}
                    onChange={handleChangeOfQuantityForGasCalc}
                    min="1"
                    style={{
                      width: "142px",
                      height: "18px",
                      marginBottom: "0px",
                      textAlign: "right",
                      fontFamily: "courier, cursive",
                      fontSize: "12pt",
                    }}
                  />
                </div>
                <div className="form-row">
                  <label htmlFor="complexitySelection">
                    Receiver complexity:
                  </label>
                  <select
                    id="complexitySelection"
                    value={selectedComplexityForGasCalc}
                    onChange={handleChangeOfComplexityForGasCalc}
                    style={{
                      fontFamily: "courier, cursive",
                      fontSize: "12pt",
                    }}
                  >
                    <option value="1">low (1 store)</option>
                    <option value="2">medium (&gt; store)</option>
                    <option value="3">high</option>
                  </select>
                </div>
              </form>
              <div className="centered-icon-container" onClick={handleGetCost}>
                <button
                  className="capsule-button"
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = "#ccc")
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = "#f5f5f5")
                  }
                >
                  GET COST
                </button>
              </div>
              {calcIsProcessing === true && (
                <div className="form-row pending-container">
                  <img src={icosaspin} alt="GIF 1" className="gif" />
                  <h3>...calculating...</h3>
                  <img src={icosaspin} alt="GIF 1" className="gif" />
                </div>
              )}
              {calculationResult != null && (
                <div className="form-row result-container">
                  <h3>Native token: {calculationResult}</h3>
                </div>
              )}
            </TabPanel>
            <TabPanel className="TabPanel">
              <h2 className="Title">GET RANDOMNESS NOW!</h2>
              <div className="centered-container">
                <ul
                  className="bulleted-text"
                  style={{
                    marginTop: "1rem",
                  }}
                >
                  <li>Request directly from your wallet</li>
                  <li>Results and signature stored on-chain</li>
                  <li>Requests receive a unique Id</li>
                  <li>Check results on the 'check' tab</li>
                  <li>Unused token refunded</li>
                </ul>
              </div>

              <div>
                <div className="centered-icon-container">
                  <button
                    disabled={connecting}
                    onClick={() => (wallet ? disconnect(wallet) : connect())}
                    style={{
                      width: "320px",
                    }}
                    className="capsule-button"
                    onMouseEnter={(e) =>
                      (e.target.style.backgroundColor = "#ccc")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.backgroundColor = "#f5f5f5")
                    }
                  >
                    {connecting
                      ? "connecting"
                      : wallet
                        ? "DISCONNECT WALLET"
                        : "CONNECT WALLET"}
                  </button>
                </div>
                <form className="Form">
                  <div className="form-row">
                    <label htmlFor="quantityInput">Number of numbers:</label>
                    <input
                      id="quantityInput"
                      type="number"
                      value={selectedQuantityForExecution}
                      onChange={handleChangeOfQuantityForExecution}
                      min="1"
                      style={{
                        width: "112px",
                        height: "18px",
                        marginBottom: "0px",
                        textAlign: "right",
                        fontFamily: "courier, cursive",
                        fontSize: "12pt",
                      }}
                    />
                  </div>
                  <div className="form-row" style={{ gap: "38px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label htmlFor="maxUint" style={{ marginRight: "0px", width: "120px" }}>Max uint256</label>
                      <input
                        type="radio"
                        id="maxUint"
                        name="numberType"
                        value="maxUint"
                        checked={selectedNumberType === 'maxUint'}
                        onChange={handleChangeOfNumberType}
                      />
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label htmlFor="numberInRange" style={{ marginRight: "0px", width: "120px", marginLeft: "0px" }}>Num in range</label>
                      <input
                        type="radio"
                        id="numberInRange"
                        name="numberType"
                        value="numberInRange"
                        checked={selectedNumberType === 'numberInRange'}
                        onChange={handleChangeOfNumberType}
                      />
                    </div>
                  </div>

                  <div className="form-row" style={{ gap: "0px", width: "320px" }}>
                    <label htmlFor="minNumber" style={{ width: "30px" }}>Min</label>
                    <input
                      id="minNumber"
                      type="number"
                      disabled={selectedNumberType === 'maxUint'}
                      value={selectedNumberType === 'maxUint' ? 0 : minNumber}
                      onChange={handleMinNumberChange}
                      style={{
                        width: "100px",
                        marginRight: "4px",
                        marginBottom: "0px",
                        textAlign: "right",
                        fontFamily: "courier, cursive",
                        fontSize: "12pt",
                      }}
                    />
                    <label htmlFor="maxNumber" style={{ width: "30px", marginLeft: "5px" }}>Max</label>
                    <input
                      id="maxNumber"
                      type="number"
                      disabled={selectedNumberType === 'maxUint'}
                      value={selectedNumberType === 'maxUint' ? 0 : maxNumber}
                      onChange={handleMaxNumberChange}
                      style={{
                        width: "110px",
                        marginLeft: "4px",
                        marginBottom: "0px",
                        textAlign: "right",
                        fontFamily: "courier, cursive",
                        fontSize: "12pt",
                      }}
                    />
                  </div>
                </form>

                <div className="centered-icon-container">
                  <button
                    className="capsule-button"
                    onClick={handleGetRandom}
                    disabled={requestIsProcessing}
                    onMouseEnter={(e) =>
                      (e.target.style.backgroundColor = "#ccc")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.backgroundColor = "#f5f5f5")
                    }
                  >
                    GET RANDOM
                  </button>
                </div>
              </div>
              {requestIsProcessing === true && (
                <div className="form-row pending-container">
                  <img src={icosaspin} alt="GIF 1" className="gif" />
                  <h3>...waiting for txn...</h3>
                  <img src={icosaspin} alt="GIF 1" className="gif" />
                </div>
              )}
              {requestID != null && (
                <div className="form-row result-container">
                  <h3
                    style={{
                      marginBottom: "0rem",
                    }}
                  >
                    Request ID: {parseInt(requestID, 16)}
                  </h3>
                </div>
              )}
            </TabPanel>
            <TabPanel className="TabPanel">
              <h2 className="Title">CHECK RESPONSE</h2>
              <br></br>
              <div>
                <form className="Form" onSubmit={handleGetResponse}>
                  <div className="form-row">
                    <label htmlFor="chainSelection">Request chain:</label>
                    <select
                      id="chainSelection"
                      value={selectedChainForResponse}
                      onChange={handleChangeOfChainForResponse}
                      style={{
                        fontFamily: "courier, cursive",
                        fontSize: "12pt",
                      }}
                    >
                      <option value="1">eth mainnet</option>
                      {/* <option value="0">BLAST mainnet SOON</option> */}
                      <option value="42161">arbitrum one</option>
                      <option value="10">optimism</option>
                      <option value="137">polygon</option>
                      {/* <option value="168587773">BLAST sepolia</option>
                      <option value="5">eth goerli</option> */}
                      <option value="11155111">eth sepolia</option>
                      {/* <option value="421613">arbitrum goerli</option>
                      <option value="420">optimism goerli</option>
                      <option value="80001">polygon mumbai</option> */}
                    </select>
                  </div>
                  <div className="form-row">
                    <label htmlFor="responseIDInput">Your response ID:</label>
                    <input
                      id="responseIDInput"
                      type="number"
                      value={requestIDForCheck}
                      onChange={handleChangeOfrequestIDForCheck}
                      style={{
                        width: "142px",
                        height: "18px",
                        marginBottom: "0px",
                        textAlign: "right",
                        fontFamily: "courier, cursive",
                        fontSize: "12pt",
                      }}
                    />
                  </div>
                </form>
              </div>
              <div className="centered-icon-container">
                <button
                  className="capsule-button"
                  onClick={handleOpenArrscan}
                  disabled={requestIsProcessing}
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = "#ccc")
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = "#f5f5f5")
                  }
                >
                  ARRSCAN
                </button>
                <button
                  className="capsule-button"
                  onClick={handleGetResponse}
                  disabled={requestIsProcessing}
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = "#ccc")
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = "#f5f5f5")
                  }
                >
                  CHECK
                </button>
              </div>

              {checkIsProcessing === true && (
                <div className="form-row pending-container">
                  <img src={icosaspin} alt="GIF 1" className="gif" />
                  <h3>..searching...</h3>
                  <img src={icosaspin} alt="GIF 1" className="gif" />
                </div>
              )}
              {noResponseYet === true && (
                <div className="form-row result-container">
                  <h3>No response, yet..</h3>
                </div>
              )}
              {requestResponse != null && (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      marginBottom: "0.5rem",
                    }}
                  >
                    <label style={{ minWidth: "100px", marginTop: "1rem" }}>
                      Numbers:
                    </label>
                    <div style={{ flex: "1", marginTop: "1rem" }}>
                      <textarea
                        readOnly
                        style={{
                          width: "100%",
                        }}
                        value={JSON.stringify(
                          requestResponse.args.randomNumbers.map((num) =>
                            num.toString(),
                          ),
                        )}
                      />
                    </div>
                  </div>
                </div>
              )}
              {requestResponse != null && (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      marginBottom: "0.5rem",
                    }}
                  >
                    <label style={{ minWidth: "100px" }}>
                      random.org response:
                    </label>
                    <div style={{ flex: "1" }}>
                      <textarea
                        readOnly
                        style={{
                          width: "100%",
                        }}
                        value={JSON.stringify(requestResponse.args.apiResponse)
                          .replace(/\\/g, "")
                          .replace(/^"|"$/g, "")}
                      />
                    </div>
                  </div>
                </div>
              )}
              {requestResponse != null && (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      marginBottom: "0.5rem",
                    }}
                  >
                    <label style={{ minWidth: "100px" }}>
                      random.org signature:
                    </label>
                    <div style={{ flex: "1" }}>
                      <textarea
                        readOnly
                        style={{
                          width: "100%",
                        }}
                        value={JSON.stringify(
                          requestResponse.args.apiSignature,
                        ).replace(/^"|"$/g, "")}
                      />
                    </div>
                  </div>
                </div>
              )}
            </TabPanel>
            <TabPanel className="TabPanel">
              <h2 className="Title">VERIFY SIGNATURE</h2>
              <div className="centered-container">
                <ul
                  className="bulleted-text"
                  style={{
                    marginTop: "1rem",
                  }}
                >
                  <li>Verify directly on random.org</li>
                  <li>You need the response and the signature</li>
                  <li>The response and signature are on-chain</li>
                  <li>Get them from chain events or the 'check' tab</li>
                  <li>You can view all requests on arrscan</li>
                </ul>
              </div>
              <div className="centered-icon-container">
                <div>
                  <button
                    className="capsule-button"
                    onClick={handleOpenArrscan}
                    onMouseEnter={(e) =>
                      (e.target.style.backgroundColor = "#ccc")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.backgroundColor = "#f5f5f5")
                    }
                  >
                    ARRSCAN
                  </button>
                </div>
                <div>
                  <button
                    className="capsule-button"
                    onClick={handleOpenRandomOrg}
                    onMouseEnter={(e) =>
                      (e.target.style.backgroundColor = "#ccc")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.backgroundColor = "#f5f5f5")
                    }
                  >
                    RANDOM.ORG
                  </button>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
      <ReactModal
        isOpen={errorModalOpen}
        onRequestClose={closeErrorModal}
        contentLabel="Error Modal"
        className="error-modal" // Apply the error-modal class
        overlayClassName="error-modal-overlay" // Apply an overlay class if needed
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.25)", // Change the overlay background color
          },
          content: {
            backgroundColor: "rgba(255, 255, 255, 0.95)", // Change the overlay background color

            border: "none", // Optional: Remove border if needed
            color: "black",
          },
        }}
      >
        <h2 style={{ textAlign: "center" }}>error</h2>
        <p style={{ textAlign: "center" }}>{errorMessage}</p>
        <button
          onClick={closeErrorModal}
          style={{
            backgroundColor: "black", // Change the button background color
            color: "white", // Change the button text color
            border: "none",
            borderRadius: "18px", // Optional: Add border radius
            padding: "8px 12px", // Optional: Adjust padding
            cursor: "pointer",
            fontFamily: "courier",
            fontSize: "11pt",
          }}
        >
          CLOSE
        </button>
      </ReactModal>
    </div>
  )
}

export default App
